<template>
    <v-row>
        <v-col cols="6">
            <v-btn @click="$emit('save', $event)" color="primary">Salva</v-btn>
            <v-btn @click="$router.go(-1)" class="mx-2">Annulla</v-btn>
        </v-col>
    </v-row>
</template>

<script>

    export default {
        name: "FormButtons",

    }
</script>
